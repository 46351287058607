// libraries
import axios from "axios";
import moment from "moment";
// internal
import { DOMAIN, ROUTES } from "@/utils/constants";
import { getAssignments } from "@/utils/newDbUtils";

const url = `https://cocue${
  process.env.VUE_APP_NODE_ENV === "production" ? "hub" : "dev"
}.com/api/slack`;

export const workspaces = [
  {
    name: "cue-fc",
    id: "T0299B3FPRC",
  },
  {
    name: "Cue Staff",
    id: "TD7JWK0A1",
  },
  {
    name: "PM",
    id: "T0RH3FLDD",
  },
];

const channels = [
  // cue-fc workspace
  { name: "fc-and-music-questions", id: "C02AN9A3G0H", mode: ["assignment"] },
  {
    // Apps
    name: "fc-apps",
    id: "C02E82VNVNU",
    conditions: {
      mediaType: ["Apps"],
      keyword: ["Today", "Arcade", "MAS"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":space_invader:",
      useFile: true,
    },
  },
  {
    name: "fc-apps-discovery",
    id: "C02D3NBTQH5",
    conditions: {
      mediaType: ["Apps"],
      keyword: ["Discovery"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":space_invader:",
      useFile: true,
      wordCount: true,
      note: "\n  • In Discovery files, the established character limits for *taglines is 30 characters including spaces*.\n  • Please check this thread for GEO-specific deadlines, role distribution, glossaries and other useful information when available.",
    },
  },
  {
    // Books
    name: "fc-books",
    id: "C02EATHCFUZ",
    conditions: {
      mediaType: ["Books", "Mktg Apple Books"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":book:",
    },
  },
  {
    // Fitness+
    name: "fc-fitness",
    id: "C02DYDG8JQJ",
    conditions: {
      mediaType: ["Fitness+", "Mktg Apple Fitness+"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":person_in_lotus_position:",
    },
  },
  {
    // Marketing (Media Type only)
    name: "fc-marketing",
    id: "C02EDHBDLE5",
    conditions: {
      mediaType: [
        "Mktg App Store",
        "Mktg Apple Arcade",
        "Mktg Apple Gift Card",
        "Mktg Apple Maps",
        "Mktg Apple Music",
        "Mktg Apple News",
        "Mktg Apple One",
        "Mktg Apple Pay",
        "Mktg Apple TV app",
        "Mktg Apple TV+",
        "Mktg iCloud",
        "Mktg Payments Marketing",
        "Mktg Shazam",
        "Mktg X-Content/Other",
      ],
    },
    blockBuilder: {
      mediaTypeEmoji: "*@*",
    },
  },
  {
    // Marketing (Keyword only)
    name: "fc-marketing",
    id: "C02EDHBDLE5",
    conditions: {
      keyword: [
        "Mktg Email",
        "Mktg Event",
        "Mktg iFrame",
        "Mktg In-app event",
        "Mktg Landing page",
        "Mktg Laurel",
        "Mktg Motion/trailer",
        "Mktg Multiple deliverables",
        "Mktg Other",
        "Mktg Partner training",
        "Mktg Pedigree",
        "Mktg Product page",
        "Mktg Push notifications",
        "Mktg Release date",
        "Mktg Search ads",
        "Mktg Search keywords",
        "Mktg Social post copy",
        "Mktg Static",
        "Mktg Subtitles",
        "Mktg Tagline",
        "Mktg Talent name",
        "Mktg Title",
        "Mktg Voice-over script",
        "Mktg YouTube",
      ],
    },
    blockBuilder: {
      mediaTypeEmoji: "*@*",
    },
  },
  {
    // Artist Bio
    name: "fc-music-bios-deu",
    id: "C02D3LXD3GX",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "de-DE",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    name: "fc-music-bios-ita",
    id: "C02DJCP4LNP",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "it-IT",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    name: "fc-music-bios-mex",
    id: "C02DBLBKE6A",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "es-MX",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    name: "fc-music-bios-rus",
    id: "C02D3M3RLJK",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "ru-RU",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    // Music Interview Localization
    name: "fc-music-interview-loc",
    id: "C02DY02HMFF",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Interviews"],
      requestType: "Localization",
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new a new music artist interview to localize!",
      mediaTypeEmoji: ":musical_note:",
      wordCount: true,
      note: "\n  • Remember to stay close to the English while ensuring natural flow in the target language and to include the HTML code.\n  • Please check this thread for glossary, copy points and other useful information when available.",
    },
  },
  {
    // Music Promotions
    name: "fc-music-promotions",
    id: "C02EDJ3D0M7",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Promotions"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got a new a new music promotion to work on!",
      mediaTypeEmoji: ":musical_note:",
    },
  },
  {
    // Weekly Music
    name: "fc-music-weekly",
    id: "C02DYCK8YQK",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Weekly"],
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new a new batch of weekly music tasks to work on!",
      mediaTypeEmoji: ":musical_note:",
    },
  },
  {
    // Generic
    name: "fc-other",
    id: "C02EQNWBZUY",
    generic: true,
  },
  { name: "fc-pilot-kr", id: "C035V9B2Z25" },
  {
    // Podcasts
    name: "fc-podcasts",
    id: "C02DY98NXPV",
    conditions: {
      mediaType: ["Podcasts", "Mktg Apple Podcasts"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":studio_microphone:",
    },
  },
  {
    // Video Promotions (combined conditions)
    name: "fc-video-promotions",
    id: "C02DL84BH71",
    conditions: {
      mediaType: ["Video"],
      keyword: ["Promotions"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got a new a new video promotion to work on!",
      mediaTypeEmoji: ":movie_camera:",
      separateKeyword: true,
      note: "\n  • The exact copy length can be found in in the *Video: Reference Guide* in <https://copy.apple.com/|*Copy*>.\n  • For local TV+ titles, please reference each market’s storefront.\n  • Please check this thread for glossary, copy points and other useful information when available.",
    },
  },
  {
    // Video Promotions (Media Type only)
    name: "fc-video-promotions",
    id: "C02DL84BH71",
    conditions: {
      mediaType: ["TV+"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got a new a new video promotion to work on!",
      mediaTypeEmoji: ":movie_camera:",
      separateKeyword: true,
      note: "\n  • The exact copy length can be found in in the *Video: Reference Guide* in <https://copy.apple.com/|*Copy*>.\n  • For local TV+ titles, please reference each market’s storefront.\n  • Please check this thread for glossary, copy points and other useful information when available.",
    },
  },
  {
    // Shakespeare Video
    name: "fc-video-shakespeare",
    id: "C02HRR16AES",
    conditions: {
      mediaType: ["Video"],
      keyword: ["Apple Music"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":movie_camera:",
      useNote: true,
      bug: true,
    },
  },
  {
    // Weekly Video
    name: "fc-video-weekly",
    id: "C02DVCAMKGV",
    conditions: {
      mediaType: ["Video"],
      keyword: ["Weekly"],
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new a new batch of weekly video tasks to work on!",
      mediaTypeEmoji: ":movie_camera:",
    },
  },
  {
    // Global Brands Playlists
    name: "global-brands-playlists",
    id: "C02DHEQAQ5D",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Playlists"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got new Daily Playlists to create notes for!",
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
    },
  },
  {
    // Shakespeare Music Playlists
    name: "music-classical-playlists",
    id: "C03511GEF1V",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Apple Music"],
      assignmentTitle: ["Classical Playlist Copy"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
      bug: true,
    },
  },
  {
    name: "music-shakespeare",
    id: "C02DV5U8N9K",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Apple Music"],
      assignmentTitle: [
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Album Review",
        "Hero Blurb",
        "Custom Title",
      ],
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
      bug: true,
    },
  },
  {
    name: "music-shakespeare-titles",
    id: "C03EF8CTMRA",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Apple Music"],
      assignmentTitle: ["Playlist Title"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
      bug: true,
    },
  },
  {
    // Smartling
    name: "fc-smartling",
    id: "C02E4E3BA1J",
    conditions: {
      fileLocation: "Smartling",
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new :sparkles:*Smartling*:sparkles: assignment!",
      mediaTypeEmoji: ":newspaper:",
    },
  },
  {
    name: "shakespeare-reassignments",
    id: "C03A42857D5",
    mode: ["reassignment"],
  },
  { name: "fc-pm-handover", id: "C06FRGM88UB", mode: ["task", "assignmentManagers"] },
  { name: "film-tv-client-us", id: "C0153BRJ1GA", mode: ["filmTvMessages"] },
  {
    name: "fc-project-availability",
    id: "C03RV897X1Q",
    mode: ["defaultCapacity"],
  },
  { name: "cuehub-feedback", id: "C03S6JK329L", mode: ["bug"] },
  {
    name: "shakespeare-bug-report",
    id: "C0423N34RFT",
    mode: ["shakespeare-bug"],
  },
  {
    name: "staff-invoice-questions",
    id: "C047X9GQVMJ",
    mode: ["invoice"],
  },
  // Co. Cue workspace
  {
    name: "cuedata-profileupdates",
    id: "C03E6FN20KC",
    mode: ["profileUpdate"],
  },
  {
    name: "admin",
    id: "C0YTX0UAV",
    mode: ["hours", "reimbursement", "vendor"],
  },
  {
    name: "global-work-and-time-off-calendar",
    id: "C016V1XRMC5",
    mode: ["availability"],
  },
];

const teams = [
  { name: "FC Team", id: "S02F8KQQVJS", mode: "assignment" },
  { name: "Daily Shaky Team", id: "S02FFC5T5MZ", mode: "reassignment" },
  { name: "CueHub Development Team", id: "S03QZDE4PC6", mode: "bug" },
];

function isWithin48Hours(date) {
  const currentDate = new Date();
  const selectedDate = new Date(date);
  const twoDaysFromNow = new Date(currentDate.setDate(currentDate.getDate() + 2));
  return selectedDate < twoDaysFromNow;
};

const resourceCoordinator = "U01QJU3ENAE"; // Maxime Pasquier | Remove hardcode together with Lead PM (Ines Santos)

export const publishMessage = async ({
  user,
  mode,
  data,
  item,
  bulkInquiry,
  modifier
}) => {
  const handleQueryChars = (string) => {
      if (string) {
        let retVal = string;
        // handle divider for Slack rich text link
        if (retVal.includes("|")) retVal = retVal.split(" | ")[0];
        // handle + for query
        retVal = retVal.replace(/\+/g, "%2B");
        // cut space at the end of string
        retVal = retVal.replace(/\s+$/g, "");
        return retVal;
      }
    },
    getBlockData = (mode, getPreview) => {
      const blocks = [
          {
            mode: "assignment",
            preview: "New Question!",
            text: `*New Question* from ${user}\n\n\`To\`: <!subteam^${
              teams.find((team) => team.mode === "assignment").id
            }>\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${data?.category}\n\n\`Assignment Title${
              bulkInquiry?.length
                ? `s\`:\n${bulkInquiry
                    .map(
                      (assgn) =>
                        `     ○ <${DOMAIN}/${
                          ROUTES.assignmentDashboard
                        }?ppm=${moment(assgn?.schedule?.payPeriod).format(
                          "MMMM"
                        )}&ppy=${moment(assgn?.schedule?.payPeriod).format(
                          "YYYY"
                        )}&Search=${handleQueryChars(
                          assgn?.assignmentDetails?.assignmentDetail
                        )}&Geo=${assgn?.assignmentDetails?.geo}|${
                          assgn?.assignmentDetails?.assignmentDetail
                        }>`
                    )
                    .join("\n")}`
                : `\`: <${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${moment(
                    item?.schedule?.payPeriod
                  ).format("MMMM")}&ppy=${moment(
                    item?.schedule?.payPeriod
                  ).format("YYYY")}&Search=${handleQueryChars(
                    item?.assignmentDetails?.assignmentDetail
                  )}&Geo=${item?.assignmentDetails?.geo}|${
                    item?.assignmentDetails?.assignmentDetail
                  }>\n\n\`Question\`: ${data?.question}`
            }\n\n:arrow_right:  A member of the ${
              teams.find((team) => team.mode === "assignment").name
            } will reply in thread. Stay tuned!`, // TODO - Slack btn "Claim for Review"
          },
          {
            mode: "filmTvMessages",
            preview: "New Assignment Alert!",
            text: `${`:loudspeaker: *New Assignment Alert:*\n\n• :movie_camera: Video: “${item?.assignmentDetails?.assignmentTitle}”\n${data[0]?.link ? `• :memo: Document: <${data[0]?.link}| ${item?.assignmentDetails?.assignmentTitle}>`:``}\n• :file_folder: Files: Check the thread below for your assignments.\n• :alarm_clock: Deadline:
                ○ Primary: ${item?.schedule?.primaryDueDate}, ${item?.schedule?.primaryDueTime} EST
                ${item?.schedule?.secondaryDueDate ? `○ Secondary: ${item?.schedule?.secondaryDueDate}, ${item?.schedule?.secondaryDueTime} EST` : ""}`}
              ${`\n:white_check_mark: Update the “Assignment Status” field on your CueHub Dashboard for progress and completion tracking.`}
              ${`\n:slack: Use the dedicated Slack button on your CueHub Dashboard for questions and notes to reviewers.`}
              \n:sparkles: Thank you! Have fun writing! :sparkles:`
          },
          {
            mode: "invoice",
            preview: "New Invoice Question!",
            text: `*New Invoice Question* from ${user}\n\n\`To\`: <!channel>\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${data?.category}\n\n\`Assignment Title\`: <${
              data?.link?.split("?")[0]
            }?ppm=${moment(item?.schedule?.payPeriod).format(
              "MMMM"
            )}&ppy=${moment(item?.schedule?.payPeriod).format(
              "YYYY"
            )}&Search=${handleQueryChars(
              item?.assignmentDetails?.assignmentDetail
            )}&Geo=${item?.assignmentDetails?.geo}|${handleQueryChars(
              item?.assignmentDetails?.assignmentDetail
            )}>\n\n\`Question\`: ${data?.question}`, // TODO - Slack btn "Claim for Review"
          },
          {
            mode: "shakespeare-bug",
            preview: "New Shakespeare bug reported!",
            text: `*New Bug* flagged by ${user}\n\n\`Assignment Title\`: ${
              item?.AssignmentDetail
            }\n\`GEO\`: ${item?.assignmentDetails?.geo}\n\`Media Type\`: ${
              item?.MediaType
            }\n\`Bug Type\`: <${DOMAIN}/${ROUTES.issueTracker}?Type=${
              data?.shkBugType
            }&Media=${item?.MediaType}&Search=${handleQueryChars(
              item?.AssignmentDetail
            )}&Geo=${item?.assignmentDetails?.geo}|${data?.shkBugType}>`,
          },
          {
            mode: "task",
            preview: "New Question!",
            text: `*New Question* from ${user}\n\n\`To\`: ${
              data?.messageTarget?.length ? data?.messageTarget?.map(item => item?.ping ? item?.ping : null).join(',') : null
            }\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${data?.category}\n\n\`Task ID\`: <${DOMAIN}/${
              ROUTES.pmDashboard
            }?Search=${
              handleQueryChars(item?.cueTaskId) + (item?.taskId ? ("|" + item?.taskId) : "")
            }>\n\n\`Question\`: ${
              data?.question
            }\n\n:arrow_right:  A member of the PM Team will reply in thread. Stay tuned!`, // TODO - Slack btn "Claim for Review"
          },
          {
            mode: "assignmentManagers",
            preview: "New Question!",
            text: `*New Question* from ${user}\n\n\`To\`: ${
              data?.messageTarget?.length ? data?.messageTarget?.map(item => item?.ping ? item?.ping : null).join(',')  : null
            }\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${data?.category}\n\n\`Assignment Link\`: <${DOMAIN}/${
              ROUTES.assignmentDashboard
            }?Search=${
              handleQueryChars(item?.assignmentDetails?.assignmentId ? item?.assignmentDetails?.assignmentId : item?.assignmentDetails?.assignmentDetail)
              + (item?.assignmentDetails?.assignmentDetail ? ("|" + item?.assignmentDetails?.assignmentDetail) : "")
            }>\n\n\`Question\`: ${
              data?.question
            }\n\n:arrow_right:  A member of the PM Team will reply in thread. Stay tuned!`,
          },
          {
            mode: "bug",
            preview: "New CueHub Feedback!",
            text: `:robot_face: *New CueHub Feedback* :robot_face:\n\n\`To\`: <!subteam^${
              teams.find((team) => team.mode === "bug").id
            }>\n\n\`From\`: ${user}\n\n\`Category\`: ${
              data?.category
            }\n\n\`Link\`: ${data?.link}\n\n\`Issue Description\`: ${
              data?.question
            }`, // TODO - Slack btn "On it!"
          },
          {
            mode: "availability",
            preview: "New Availability Record!",
            text: `${user} will be offline ${
              data?.to
                ? `from *${moment(data?.from).format(
                    "MM/DD/YYYY"
                  )}* to *${moment(data?.to).format("MM/DD/YYYY")}*`
                : `on *${moment(data?.from).format("MM/DD/YYYY")}*`
            } :arrow_right: ${data?.note}.`,
          },
          {
            mode: "defaultCapacity",
            preview: "New Capacity Record!",
            text: `${user}'s default capacity changed:\n     • *LOC Translating Daily Workload*: ${data?.locDefault}\n     • *LOC Editing Daily Workload*: ${data?.editDefault}.`,
          },
          {
            mode: "profileUpdate",
            preview: "Staff Profile Updated",
            text: `:rolled_up_newspaper: Good news! ${user} has just updated their personal profile (${
              data?.keys
            }).\n:arrow_right: Feel free to check <${DOMAIN}/${
              ROUTES.staff
            }?ActiveStaff=${data?.isActive}&Search=${user?.replace(
              /\*/g,
              ""
            )}|Staff Table> for more details.`,
          },
          {
            mode: "reassignment",
            preview: "New Reassignment Request!",
            text: `*New Reassignment Request* from ${user}\n\n\`To\`: <!subteam^${
              teams.find((team) => team.mode === "reassignment").id
            }>\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${
              data?.category
            }\n\n\`Assignment Title\`: <${DOMAIN}/${
              ROUTES.assignmentDashboard
            }?ppm=${moment(item?.schedule?.payPeriod).format(
              "MMMM"
            )}&ppy=${moment(item?.schedule?.payPeriod).format(
              "YYYY"
            )}&Search=${handleQueryChars(
              item?.assignmentDetails?.assignmentDetail
            )}&Geo=${item?.assignmentDetails?.geo}&Shakespeare=0|${
              item?.assignmentDetails?.assignmentDetail
            }>\n\n\`Workflow\`: ${
              item?.assignmentDetails?.mediaType
            }\n\n\`Details\`: ${
              data?.question
            }\n\n:arrow_right: A member of the ${
              teams.find((team) => team.mode === "reassignment").name
            } Team will reply in thread. Stay tuned!`, // TODO - Slack btn "Claim for Review"
          },
          {
            mode: "request",
            preview: "New Client Request!",
            text: `New *${data?.project}*${
              data?.project?.includes("Request") ? "" : " Request"
            }\n\n:memo: Assignment Title: *${data?.assignmentDetail}* | ${
              data?.requestType
            }\n\n:earth_americas: GEOs: *${data?.geos?.join(", ")}*${
              data?.design
                ? `\n\n:triangular_ruler: Design needed? *${data?.design}*`
                : ""
            }\n\n:date: Due Date: *${moment(data?.deliveryDate).format(
                    "YYYY-MM-DD"
                  )}*${
              data?.wordCount
                ? `\n\n:1234: Estimated Word Count: *${data?.wordCount}*`
                : ""
            }\n\n:bust_in_silhouette: Client Requester: ${
              data?.newClientRequester
                ? `${data?.newClientRequester}`
                : `*${data?.clientRequester}*`
            }${data?.inquiryEmail ? ` | ${data?.inquiryEmail}` : ""
            }\n\nAny additional information will be added in the thread.`,
          },
          {
            mode: "hours",
            preview: "New Staff Hours Report!",
            text: `*${
              data?.user
            }* has added a new entry to the *Staff Hours* Form.\n:arrow_right: *${
              data?.quantity
            } hours* between ${moment(data?.requestDate).format(
              "YYYY-MM-DD"
            )} and ${moment(data?.deliveryDate).format("YYYY-MM-DD")} (${
              data?.requestType
            }${data?.mediaType ? ` | ${data?.mediaType}` : ""} | ${data?.geo})`,
          },
          {
            mode: "reimbursement",
            preview: "New Reimbursement Request!",
            text: `:moneybag: *New Reimbursement Request *\n\n:arrow_right: *${data?.user}* | ${data?.project} | ${
              data?.workCategory
            }\n\n:dollar: *$${data?.quantity}* | ${
              data.requestType1 ?? data.requestType2?.join(", ")
            } | ${moment(data?.deliveryDate).format(
              "YYYY-MM-DD"
            )}\n\n:arrow_right: Related Project Manager: ${
              data?.pmSlackTag
            } | See additional notes in thread.`,
          },
          {
            mode: "reimbursementConfirmation",
            preview: "We are processing your Reimbursement Request!",
            text: `Hi <@${data?.userSlackTag}>! Thank you for filling your Reimbursement Request!\nYour Project Manager will check that and reach out if they have any questions!`,
          },
          {
            mode: "vendor",
            preview: "New Vendor Invoice form!",
            text: `:moneybag: *New Vendor Invoice*\n\n:arrow_right: *${data?.user}* | ${
              data?.workCategory
            }\n\n:dollar: *$${data?.quantity}* | ${moment(data?.deliveryDate).format(
              "YYYY-MM-DD"
            )}\n\n:arrow_right: See additional notes in thread.`,
          },
        ],
        blockToUse = blocks.find((block) => block.mode === mode);
      return getPreview ? blockToUse.preview : blockToUse.text;
    };

  const slackEnv = () => {
    if (
      mode === "profileUpdate" ||
      mode === "request" ||
      mode === "hours" ||
      mode === "reimbursement" ||
      mode === "availability" ||
      mode === "vendor"
    )
      return "VUE_APP_CO_CUE_SLACK_TOKEN";
    else if (
      mode === "reimbursementConfirmation" ||
      mode === "nonFcNotification" ||
      mode === "filmTvMessages"
    )
      return "VUE_APP_CUE_STAFF_SLACK_TOKEN";
    else return "VUE_APP_CUE_FC_SLACK_TOKEN";
  };

  let messageData = {
      token: process.env[slackEnv()]
    },
    geosThreads = [];
  if (mode === "nonFcNotification") {
    messageData.assignmentId = item?.id ? item?.id : null;
  }
  if (mode.includes("Notification")) {
    const geo1Label = Object.keys(item.geos[0])[0],
      geo1Obj = item.geos[0][geo1Label];
    let targetChannel;
    const generateFcNotificationBlock = (channel, item) =>
      `${`:speaking_head_in_silhouette: *New Assignment Alert:*\n\n${
        channel.blockBuilder?.title ?? "Hi team, you’ve got a new assignment!"
      }`}\n\n${
        channel?.generic
          ? ""
          : `${modifier === "adaptation" || modifier === "copywriting" ? ":file_cabinet:" : channel.blockBuilder?.mediaTypeEmoji} *Media Type:* ${item.assignmentDetails.mediaType} | `
      }${
        channel.blockBuilder?.separateKeyword
          ? `:key: *Keyword:* ${item.assignmentDetails.keyword} | `
          : ""
      }${
        channel.blockBuilder?.useNote
          ? ":memo: *Note"
          : `:file_cabinet: *${
              channel.blockBuilder?.useFile ? "File" : "Request"
            }`
      } Type:* ${
        modifier === "adaptation"
        ? "Adaptation"
        : modifier === "copywriting"
        ? "Copywriting"
        : item.assignmentDetails[
          channel.blockBuilder?.useNote
            ? "assignmentTitle"
            : channel.blockBuilder?.useFile
            ? "keyword"
            : "requestType"
        ]
      } ${
        channel.blockBuilder?.wordCount
          ? `| :abcd: *Word Count:* ${item.assignmentDetails.wordCount} `
          : ""
      }| :round_pushpin: *File Location:* ${
        item.assignmentDetails.fileLocation
      }\n\n${
        item.geos.length === 1
          ? `:open_file_folder: *File Names:*\n${geo1Obj.assignments
              .map(
                (assgn) =>
                  `     ○ <${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${moment(
                    item.schedule.payPeriod
                  ).format("MMMM")}&ppy=${moment(
                    item.schedule.payPeriod
                  ).format("YYYY")}&Search=${handleQueryChars(
                    assgn
                  )}&DeliveryDate=${item.schedule.deliveryDate}|${assgn}>`
              )
              .join("\n")}`
          : ":link: *Files:* Please find a message with your name in the thread below to see your assignments. Clicking on the file names will bring you to your _Assignments Dashboard_ in CueHub."
      }\n\n:warning: *Important Notes*: ${
        channel.blockBuilder?.note ??
        `Please check this thread for ${
          item.geos.length === 1
            ? "glossary, copy points"
            : "GEO-specific deadlines, role distribution, glossaries"
        } and other useful information when available.`
      }${
        channel.blockBuilder?.instructions
          ? "\n\n:heavy_plus_sign: *Instructions:*\n     • Please confirm the bio type based on the tier (column *J* of the file):\n\n          ○ *Tier 1:* Full bio; under 1500cc\n          ○ *Tier 2:* Baseball card style bio (bullet points); under 1500cc\n          ○ *Tier 3:* Short notes; *one full sentence*, capturing the artist’s career highlights. No cc limit.\n\n     • :musical_note: *In column J (Adam ID)*, you’ll find the corresponding ID of the artist in Apple Music.\n     • :mag: Use the English copy (*column M*) as reference, when available.\n     • :writing_hand::skin-tone-2: The bios should be added in *column N* of the Excel spreadsheet.\n     • :book: Please add your main sources in *column O*.\n     • :no_entry_sign: Do not modify any other column."
          : ""
      }${
        item.geos.length === 1
          ? `\n\n:alarm_clock: *Deadlines:*\n     ○ *Writing:* ${moment(
              geo1Obj.writingDueDate
                ? geo1Obj.writingDueDate
                : item.schedule.primaryDueDate
            ).format("dddd, M/D")}, ${
              geo1Obj.writingDue
                ? geo1Obj.writingDue
                : item.schedule.primaryDueTime
            } ET — ${geo1Obj.writers.join(", ")}.${
              geo1Obj.hasOwnProperty("editors")
                ? `\n     ○ *Editing:* ${moment(
                    geo1Obj.editingDueDate
                      ? geo1Obj.editingDueDate
                      : item.schedule.secondaryDueDate
                  ).format("dddd, M/D")}, ${
                    geo1Obj.editingDue
                      ? geo1Obj.editingDue
                      : item.schedule.secondaryDueTime
                  } ET — ${geo1Obj.editors.join(", ")}.`
                : ""
            }${
              geo1Obj.hasOwnProperty("reviewers")
                ? `\n     ○ *Review:* ${moment(
                    geo1Obj.reviewingDueDate
                      ? geo1Obj.reviewingDueDate
                      : item.schedule.qcDueDate
                  ).format("dddd, M/D")}, ${
                    geo1Obj.reviewingDue
                      ? geo1Obj.reviewingDue
                      : item.schedule.qcDueTime
                  } ET — ${geo1Obj.reviewers.join(", ")}.`
                : ""
            }`
          : ""
      }\n\n--------------------------------------------------\n\n:heavy_check_mark: Make sure to let us know you have completed your part of the task by changing the status in the “Assignment Status” field on ${
        item.geos.length === 1
          ? `<${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${moment(
              item.schedule.payPeriod
            ).format("MMMM")}&ppy=${moment(item.schedule.payPeriod).format(
              "YYYY"
            )}&Search=${handleQueryChars(
              item.assignmentDetails.assignmentTitle
            )}&DeliveryDate=${
              item.schedule.deliveryDate
            }|*MyCue Assignment Dashboard*>`
          : "*MyCue Assignment Dashboard*"
      }.\n\n:alphabet-white-question: Use the dedicated :slack: button on ${
        item.geos.length === 1
          ? `<${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${moment(
              item.schedule.payPeriod
            ).format("MMMM")}&ppy=${moment(item.schedule.payPeriod).format(
              "YYYY"
            )}&Search=${handleQueryChars(
              item.assignmentDetails.assignmentTitle
            )}&DeliveryDate=${
              item.schedule.deliveryDate
            }|*MyCue Assignment Dashboard*>`
          : "*MyCue Assignment Dashboard*"
      } to submit a note to reviewers or ask specific questions about this task (character count limit, deadlines, etc.). The Slack thread below can be used for a group discussion with the whole team.${
        channel.blockBuilder?.bug
          ? "\n\n:beetle: Please use the “Bug” button on *MyCue Assignment Dashboard* to report any technical issues in Shakespeare."
          : ""
      }\n\n:sparkles: Thank you! Have fun writing! :sparkles:`;

      const generateDECHFcNotificationBlock = (channel, item) =>
        `${`:speaking_head_in_silhouette: *New Assignment Alert:*\n\n${
          channel.blockBuilder?.title ?? "Hi team, you’ve got a new de-CH Adaptation assignment!"
        }`}\n\n${
          channel?.generic
            ? ""
            : `${":newspaper:"} *Media Type:* ${item.assignmentDetails.mediaType} | `
        }${
          channel.blockBuilder?.separateKeyword
            ? `:key: *Keyword:* ${item.assignmentDetails.keyword} | `
            : ""
        }${
          channel.blockBuilder?.useNote
            ? ":memo: *Note"
            : `:file_cabinet: *${
                channel.blockBuilder?.useFile ? "File" : "Request"
              }`
        } Type:* ${
          "Adaptation"
        } | :round_pushpin: *File Location:* ${
          item.assignmentDetails.fileLocation
        }\n\n${
          item.geos.length === 1
            ? `:open_file_folder: *File Names:*\n${geo1Obj.assignments
                .map(
                  (assgn) =>
                    `     ○ <${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${moment(
                      item.schedule.payPeriod
                    ).format("MMMM")}&ppy=${moment(
                      item.schedule.payPeriod
                    ).format("YYYY")}&Search=${handleQueryChars(
                      assgn
                    )}&DeliveryDate=${item.schedule.deliveryDate}|${assgn}>`
                )
                .join("\n")}`
            : ":link: *Files:* Please find a message with your name in the thread below to see your assignments. Clicking on the file names will bring you to your _Assignments Dashboard_ in CueHub."
        }\n${
          item.geos.length === 1
            ? `\n\n:alarm_clock: *Deadlines:*\n     ○ *Adapting:* ${moment(
                geo1Obj.writingDueDate
                  ? geo1Obj.writingDueDate
                  : item.schedule.primaryDueDate
              ).format("dddd, M/D")}, ${
                geo1Obj.writingDue
                  ? geo1Obj.writingDue
                  : item.schedule.primaryDueTime
              } ET — ${geo1Obj.writers.join(", ")}.${
                geo1Obj.hasOwnProperty("editors")
                  ? `\n     ○ *Editing:* ${moment(
                      geo1Obj.editingDueDate
                        ? geo1Obj.editingDueDate
                        : item.schedule.secondaryDueDate
                    ).format("dddd, M/D")}, ${
                      geo1Obj.editingDue
                        ? geo1Obj.editingDue
                        : item.schedule.secondaryDueTime
                    } ET — ${geo1Obj.editors.join(", ")}.`
                  : ""
              }${
                geo1Obj.hasOwnProperty("reviewers")
                  ? `\n     ○ *Review:* ${moment(
                      geo1Obj.reviewingDueDate
                        ? geo1Obj.reviewingDueDate
                        : item.schedule.qcDueDate
                    ).format("dddd, M/D")}, ${
                      geo1Obj.reviewingDue
                        ? geo1Obj.reviewingDue
                        : item.schedule.qcDueTime
                    } ET — ${geo1Obj.reviewers.join(", ")}.`
                  : ""
              }`
            : ""
        }\n\n${
          geo1Obj.reviewers?.length 
            ? `${geo1Obj.reviewers.join(", ")} please share the Word Count in this thread once you’ve completed the assignment.` 
            : `${geo1Obj.writers.join(", ")} please share the Word Count in this thread once you’ve completed the assignment.` 
        }\n\n:sparkles: Thank you! Have fun writing! :sparkles:`;

    const generateNonFcNotificationBlock = (item) =>
      `*New ${item.project.name} Assignment*:\n\n*${
        item.assignmentDetails.assignmentTitle
      }*\n\n:books: *Type:* ${item.assignmentDetails.requestType}${
        item.assignmentDetails.fileLocation
          ? ` | :compass: *Platform:* ${item.assignmentDetails.fileLocation}`
          : ""
      }\n:calendar: *Primary Due:* ${moment(
        item.schedule.primaryDueDate
      ).format("MMM D, YYYY")}, ${moment(
        item.schedule.primaryDueTime,
        "HH:mm A"
      ).format("ha")} ET${
        item.schedule.secondaryDueDate
          ? `\n:calendar: *Secondary Due:* ${moment(
              item.schedule.secondaryDueDate
            ).format("MMM D, YYYY")}${
              item.schedule.secondaryDueTime
                ? `, ${moment(item.schedule.secondaryDueTime, "HH:mm A").format(
                    "ha"
                  )} ET`
                : ""
            }`
          : ""
      }${
        item.schedule.qcDueDate
          ? `\n:calendar: *QC Due:* ${moment(
              item.schedule.qcDueDate
            ).format("MMM D, YYYY")}${
              item.schedule.qcDueTime
                ? `, ${moment(item.schedule.qcDueTime, "HH:mm A").format(
                    "ha"
                  )} ET`
                : ""
            }`
          : ""
      }`;

    // find channel for post
    if (mode === "fcNotification") {
      channels.forEach((channel) => {
        let keysValidator = [];
        if (channel.conditions)
          Object.keys(channel.conditions).forEach((key) => {
            if (Array.isArray(channel.conditions[key])) {
              keysValidator.push(
                channel.conditions[key].includes(item.assignmentDetails[key])
              );
            } else {
              keysValidator.push(
                channel.conditions[key] === item.assignmentDetails[key]
              );
            }
          });
        if (
          keysValidator.length &&
          keysValidator.every((value) => value === true)
        ) {
          targetChannel = channel;
        } else {
          keysValidator = [];
        }
      });
      if (!targetChannel)
        targetChannel = channels.find((channel) =>
          channel.hasOwnProperty("generic")
        );
      if (modifier === "adaptation") targetChannel = { id: "C04DETB7L13" };
      if (modifier === "copywriting") targetChannel = { id: "C07QLDTQ0NL" };
      if (modifier === "de-CH-adaptation") targetChannel = { id: "C05PX8Z5TAQ" };
    } else targetChannel = { id: item.project.slackChannelIdStaff ?? "UDG0U2YNN" }; // Silvana's ID as a fallback. TODO: replace with operational role request

    // set message data
    Object.assign(messageData, {
      channel: targetChannel.id,
      preview: "New Assignment Alert!",
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text:
              mode === "fcNotification" && modifier === "de-CH-adaptation"
                ? generateDECHFcNotificationBlock(targetChannel, item)
                : mode === "fcNotification"
                ? generateFcNotificationBlock(targetChannel, item)
                : mode === "filmTvMessages"
                ? generatefilmTvNotification(targetChannel, item)
                : generateNonFcNotificationBlock(item),
          },
        },
      ],
    });

    // handle block limit - 3000 characters for FC notification
    if (
      mode === "fcNotification" &&
      messageData.blocks[0].text.text.length > 3000
    ) {
      // handle single GEO message with 20+ assignments
      let splitBlocks = [];
      if (messageData.blocks[0].text.text.includes(":open_file_folder:")) {
        const introAndMain =
            messageData.blocks[0].text.text.split(":open_file_folder:"),
          filesAndNotes = introAndMain[1].split(":warning:");
        if (filesAndNotes[0].length > 3000) {
          const files = filesAndNotes[0].split("     ○");
          splitBlocks.push(
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: introAndMain[0],
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":open_file_folder:".concat(files[0]),
              },
            }
          );
          files.forEach((str) => {
            if (files.indexOf(str) !== 0)
              splitBlocks.push({
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: "     ○".concat(str),
                },
              });
          });
          splitBlocks.push({
            type: "section",
            text: {
              type: "mrkdwn",
              text: ":warning:".concat(filesAndNotes[1]),
            },
          });
        } else {
          splitBlocks.push(
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: introAndMain[0],
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":open_file_folder:".concat(filesAndNotes[0]),
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":warning:".concat(filesAndNotes[1]),
              },
            }
          );
        }
        messageData.blocks = [...splitBlocks];
      } else {
        // usual slice of message
        const sliceIndex =
          messageData.blocks[0].text.text.indexOf(":heavy_check_mark:");
        blocks.push({
          type: "section",
          text: {
            type: "mrkdwn",
            text: messageData.blocks[0].text.text.slice(sliceIndex),
          },
        });
        messageData.blocks[0].text.text = messageData.blocks[0].text.text.slice(
          0,
          sliceIndex
        );
      }
    }

    // multiple GEOs threads processing
    if (item.geos.length > 1) {
      item.geos.forEach((geo) => {
        const geoLabel = Object.keys(geo)[0],
          geoObj = geo[geoLabel];
        geosThreads.push(
          mode === "fcNotification"
            ? `:globe_with_meridians: GEO: ${geoLabel}\n\n:open_file_folder: *File Names:*\n${geoObj.assignments
                .map(
                  (assgn) =>
                    `     ○ <${DOMAIN}/${
                      ROUTES.assignmentDashboard
                    }?ppm=${moment(item.schedule.payPeriod).format(
                      "MMMM"
                    )}&ppy=${moment(item.schedule.payPeriod).format(
                      "YYYY"
                    )}&Search=${handleQueryChars(assgn)}&DeliveryDate=${
                      item.schedule.deliveryDate
                    }|${assgn}>`
                )
                .join(
                  "\n"
                )}\n\n:alarm_clock: *Deadlines for ${geoLabel}:*\n     ○ *Writing:* ${moment(
                geoObj.writingDueDate
                  ? geoObj.writingDueDate
                  : item.schedule.primaryDueDate
              ).format("dddd, M/D")}, ${
                geoObj.writingDue
                  ? geoObj.writingDue
                  : item.schedule.primaryDueTime
              } ET — ${geoObj.writers.join(", ")}.${
                geoObj.hasOwnProperty("editors")
                  ? `\n     ○ *Editing:* ${moment(
                      geoObj.editingDueDate
                        ? geoObj.editingDueDate
                        : item.schedule.secondaryDueDate
                    ).format("dddd, M/D")}, ${
                      geoObj.editingDue
                        ? geoObj.editingDue
                        : item.schedule.secondaryDueTime
                    } ET — ${geoObj.editors.join(", ")}.`
                  : ""
              }${
                geoObj.hasOwnProperty("reviewers")
                  ? `\n     ○ *Review:* ${moment(
                      geoObj.reviewingDueDate
                        ? geoObj.reviewingDueDate
                        : item.schedule.qcDueDate
                    ).format("dddd, M/D")}, ${
                      geoObj.reviewingDue
                        ? geoObj.reviewingDue
                        : item.schedule.qcDueTime
                    } ET — ${geoObj.reviewers.join(", ")}.`
                  : ""
              }`
            : `:mag: *Assignment Detail*: <${DOMAIN}/${
                ROUTES.assignmentDashboard
              }?ppm=${moment(item.schedule.payPeriod).format(
                "MMMM"
              )}&ppy=${moment(item.schedule.payPeriod).format(
                "YYYY"
              )}&Search=${handleQueryChars(item?.assignment)}&DeliveryDate=${
                item.schedule.deliveryDate
              }|${item?.assignment}>\n\n:earth_americas: *GEO*: ${geoLabel}${
                item.assignmentDetails?.taskId
                  ? ` | :id: *Task ID*: ${item.assignmentDetails?.taskId}`
                  : ""
              }\n:open_book: *Word Count*: ${
                item.assignmentDetails?.wordCount
              } | :1234: *Quantity*: ${
                item?.pricing?.workTypes[0]?.quantity ??
                item.assignmentDetails?.wordCount
              }\n\n:pencil: *Primary*: ${geoObj.writers.join(", ")}${
                geoObj?.editors?.length
                  ? `\n:ok_hand: *Secondary*: ${geoObj.editors.join(", ")}`
                  : ""
              }`
        );
      });
    }
  } else {
    const getChannel = () => {
      if (mode === "request") return data?.channel;
      else if (mode === "filmTvMessages") return item.project?.slackChannelIdStaff;
      else if (mode === "reimbursementConfirmation") return data?.userSlackTag;
      else return channels.find((channel) => channel?.mode?.includes(mode)).id;
    };
    Object.assign(messageData, {
      channel: getChannel(),
      preview: getBlockData(mode, true),
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: getBlockData(mode),
          },
        },
      ],
    });
  }

  /* try {
    let existedSlackTsItem = null;
    if (mode === "nonFcNotification") {
      const existedAssignments = await getAssignments({
        query: { 
          search: item?.assignmentDetails?.assignmentDetail ? item?.assignmentDetails?.assignmentDetail : item?.assignment,
          projectId: item?.project?.id,
          ppm: moment(item?.schedule?.payPeriod)
            .format("MMMM")
            .toUpperCase(),
          ppy: moment(item?.schedule?.payPeriod).format("YYYY"),
          size: 50
        }
      });
      existedAssignments?.content?.length
      ? existedSlackTsItem = existedAssignments?.content?.find(el => el.assignmentDetails?.slackTs 
        && el.assignmentDetails?.assignmentTitle === item?.assignmentDetails?.assignmentTitle)
      : existedSlackTsItem = null;
    }
    if (mode === "nonFcNotification" && item && item?.assignmentDetails && existedSlackTsItem && existedSlackTsItem?.assignmentDetails?.slackTs) {
      const firstGeo = Object.keys(item.geos[0])[0];
      await axios.post(`${url}/send-to-thread`, {
        token: process.env.VUE_APP_CUE_STAFF_SLACK_TOKEN,
        channel: messageData.channel,
        thread_ts: existedSlackTsItem?.assignmentDetails?.slackTs,
        blocks: [
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: `${
                item.geos.length === 1
                  ? `\n\n:mag: *Assignment Detail*: <${DOMAIN}/${
                      ROUTES.assignmentDashboard
                    }?ppm=${moment(item.schedule.payPeriod).format(
                      "MMMM"
                    )}&ppy=${moment(item.schedule.payPeriod).format(
                      "YYYY"
                    )}&Search=${handleQueryChars(item?.assignment)}&DeliveryDate=${
                      item.schedule.deliveryDate
                    }|${item?.assignmentDetails?.assignmentDetail ? item?.assignmentDetails?.assignmentDetail : item?.assignment ? item?.assignment : 'New Assignment'}>\n\n:earth_americas: *GEO*: ${Object.keys(item.geos[0])[0]}${
                      item.assignmentDetails?.taskId
                        ? ` | :id: *Task ID*: ${item.assignmentDetails?.taskId}`
                        : ""
                    }${item?.assignmentDetails?.link
                      ? `\n\n:link: : <${item?.assignmentDetails?.link}|Link>`
                      : ""
                    }\n\n:open_book: *Word Count*: ${
                      item.assignmentDetails?.wordCount
                    } | :1234: *Quantity*: ${
                      item?.pricing?.workTypes[0]?.quantity ??
                      item.assignmentDetails?.wordCount
                    }\n\n:pencil: *Primary*: ${item.geos[0][firstGeo].writers[0]}${
                      item.geos[0][firstGeo]?.editors?.length
                        ? `\n\n:ok_hand: *Secondary*: ${item.geos[0][firstGeo].editors[0]}`
                        : ""
                    }${item.geos[0][firstGeo]?.reviewers?.length
                        ? `\n\n:+1: *QC*: ${item.geos[0][firstGeo].reviewers[0]}`
                        : ""
                    }`
                  : ""
              }`,
            },
          },
        ],
      });
    } else {
      const message = await axios.post(`${url}/send-to-channel`, messageData);
      if (geosThreads.length)
        geosThreads.forEach((el) => {
          let threadBlocks = [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: el,
              },
            },
          ];
          // handle block limit - 3000 characters for FC notification
          if (mode === "fcNotification" && el.length > 3000) {
            let splitThreadBlocks = [];
            const introAndMain = el.split(":open_file_folder:"),
              filesAndDeadlines = introAndMain[1].split(":alarm_clock:"),
              files = filesAndDeadlines[0].split("     ○");
            splitThreadBlocks.push(
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: introAndMain[0],
                },
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: ":open_file_folder:".concat(files[0]),
                },
              }
            );
            files.forEach((str) => {
              if (files.indexOf(str) !== 0)
                splitThreadBlocks.push({
                  type: "section",
                  text: {
                    type: "mrkdwn",
                    text: "     ○".concat(str),
                  },
                });
            });
            splitThreadBlocks.push({
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":alarm_clock:".concat(filesAndDeadlines[1]),
              },
            });
            threadBlocks = [...splitThreadBlocks];
          }
          axios.post(`${url}/send-to-thread`, {
            token: process.env[slackEnv()],
            channel: message.data.channel,
            thread_ts: message.data.ts,
            blocks: threadBlocks,
          });
        });
      if (mode === "request") {
        axios.post(`${url}/send-to-thread`, {
          token: process.env.VUE_APP_CO_CUE_SLACK_TOKEN,
          channel: message.data.channel,
          thread_ts: message.data.ts,
          blocks: [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `:arrow_right: <${DOMAIN}/${
                  ROUTES.requestedAssignments
                }?DeliveryDate=${moment(data?.deliveryDate).format(
                  "YYYY-MM-DD"
                )}&Search=${handleQueryChars(data?.assignmentDetail)}|*Link to CueHub "Requested" Report*>${ 
                  data?.link 
                  ? `\n\n:link: Link: <${data.link}>` 
                  : "" 
                }${ 
                  data?.files?.length > 0 
                  ? `\n\n:books: Attachments: ${data.files.map((file) => file.fileName).join(",\n")}` 
                  : "" 
                }${
                  data?.relatedText ? `\n\n:pencil2: Text: ${data?.relatedText}` : ""
                }${
                  data?.noteToManager
                    ? `\n\n:arrow_right: Note to Manager: ${data?.noteToManager}`
                    : ""
                }`,
              },
            },
          ],
        });
      }
        
      if (mode === "reimbursement")
        axios.post(`${url}/send-to-thread`, {
          token: process.env.VUE_APP_CO_CUE_SLACK_TOKEN,
          channel: message.data.channel,
          thread_ts: message.data.ts,
          blocks: [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `:arrow_right: This reimbursement is for: *${
                  data?.assignmentDetail
                }*\n\n:arrow_right: <${DOMAIN}/${
                  ROUTES.requestedAssignments
                }?DeliveryDate=${moment(data?.deliveryDate).format(
                  "YYYY-MM-DD"
                )}&Search=${handleQueryChars(data?.assignmentDetail)}&Geo=${
                  data?.geo
                }|*Receipt here*>\n\n:arrow_right: Check the <${DOMAIN}/${
                  ROUTES.requestedAssignments
                }?RequestDate=${moment().format(
                  "YYYY-MM-DD"
                )}|*Requested Report in CueHub*> and fill the missing data${
                  data?.employmentType?.includes("U.S. Full Time Employee") ||
                  data?.employmentType?.includes("Non-U.S. Full Time Employee")
                    ? `\n\nHi <@${resourceCoordinator}>  – This is a *Reimbursement Request* sent by a ${
                        data?.employmentType?.includes("U.S. Full Time Employee")
                          ? "*U.S. Full Time Employee*. Please remember to submit the report linked below to *Sternbach & Rose* by the 20th of the month. Thanks!"
                          : '*retainee* staff member. Please make sure to pay it (separately) before the end of the month, and add a note in the payment that includes the word "Reimbursement".'
                      }\n\nFor reference, you might want to check this report:\n:arrow_right: <${DOMAIN}/${
                        ROUTES.requestedAssignments
                      }?RequestDate=${moment().format("YYYY-MM-DD")}&Search=${
                        data?.employmentType?.includes("U.S. Full Time Employee")
                          ? "Reimbursement Request - U.S. Full Time Employee"
                          : "Reimbursement Request Employee"
                      }|*Link to Reimbursement Requests – ${
                        data?.employmentType?.includes("U.S. Full Time Employee")
                          ? "Full-Time US Staff"
                          : "Retainees"
                      }*>\n\nOnce done, please add a message to this thread. Thanks!`
                    : ""
                }`,
              },
            },
          ],
        });
        if (mode === "vendor")
        axios.post(`${url}/send-to-thread`, {
          token: process.env.VUE_APP_CO_CUE_SLACK_TOKEN,
          channel: message.data.channel,
          thread_ts: message.data.ts,
          blocks: [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `:arrow_right: Check the <${DOMAIN}/${
                  ROUTES.requestedAssignments
                }?RequestDate=${moment().format(
                  "YYYY-MM-DD"
                )}|*Requested Report in CueHub*> and fill the missing data`,
              },
            },
          ],
        });
        if (mode === "nonFcNotification") {
          const firstGeo = Object.keys(item.geos[0])[0];
          await axios.post(`${url}/send-to-thread`, {
            token: process.env.VUE_APP_CUE_STAFF_SLACK_TOKEN,
            channel: message.data.channel,
            thread_ts: message.data.ts,
            blocks: [
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `\n\n:pushpin: Please check this assignment in CueHub. If you have any questions, please ask in thread!${
                    item.geos.length === 1
                      ? `\n\n:mag: *Assignment Detail*: <${DOMAIN}/${
                          ROUTES.assignmentDashboard
                        }?ppm=${moment(item.schedule.payPeriod).format(
                          "MMMM"
                        )}&ppy=${moment(item.schedule.payPeriod).format(
                          "YYYY"
                        )}&Search=${handleQueryChars(item?.assignment)}&DeliveryDate=${
                          item.schedule.deliveryDate
                        }|${item?.assignmentDetails?.assignmentDetail ? item?.assignmentDetails?.assignmentDetail : item?.assignment ? item?.assignment : 'New Assignment'}>\n\n:earth_americas: *GEO*: ${Object.keys(item.geos[0])[0]}${
                          item.assignmentDetails?.taskId
                            ? ` | :id: *Task ID*: ${item.assignmentDetails?.taskId}`
                            : ""
                        }${item?.assignmentDetails?.link
                          ? `\n\n:link: : <${item?.assignmentDetails?.link}|Link>`
                          : ""
                        }\n\n:open_book: *Word Count*: ${
                          item.assignmentDetails?.wordCount
                        } | :1234: *Quantity*: ${
                          item?.pricing?.workTypes[0]?.quantity ??
                          item.assignmentDetails?.wordCount
                        }\n\n:pencil: *Primary*: ${item.geos[0][firstGeo].writers[0]}${
                          item.geos[0][firstGeo]?.editors?.length
                            ? `\n\n:ok_hand: *Secondary*: ${item.geos[0][firstGeo].editors[0]}`
                            : ""
                        }${item.geos[0][firstGeo]?.reviewers?.length
                            ? `\n\n:+1: *QC*: ${item.geos[0][firstGeo].reviewers[0]}`
                            : ""
                        }`
                      : ""
                  }`,
                },
              },
            ],
          });
        }
        if (mode === "filmTvMessages") {
          const blocks = [];
          for (let i = 0; i < data.length; i++) {
            const blockItem = {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `${`\n\n:bust_in_silhouette: Primary: ${data[i].primary.cueSlackID ? `<@${data[i].primary.cueSlackID}>` : data[i].primary.user.firstName + " " + data[i].primary.user.lastName}${data[i].secondary ? `\n:bust_in_silhouette: Secondary: ${data[i].secondary.cueSlackID ? `<@${data[i].secondary.cueSlackID}>` : data[i].secondary.user.firstName + " " + data[i].secondary.user.lastName}` : ""}${data[i].qc ? `\n:bust_in_silhouette: QC: <@${data[i].qc.cueSlackID}>` : ""}\n:globe_with_meridians: GEO: ${data[i].geo.join(', ')}\n:open_file_folder: File Names:${data[i].files.length ? data[i].files.map(file => `\n${' '.repeat(6)}○ <${DOMAIN}/${
                  ROUTES.assignmentDashboard
                }?ppm=${moment(file.payPeriod).format(
                  "MMMM"
                )}&ppy=${moment(file.payPeriod).format(
                  "YYYY"
                )}&Search=${handleQueryChars(file.assignmentDetails)}|${file.assignmentDetails}>`).join('') : ""}
              `}`,
              },
            }
            blocks.push(blockItem);
          }
          await axios.post(`${url}/send-to-thread`, {
            token: process.env.VUE_APP_CUE_STAFF_SLACK_TOKEN,
            channel: message.data.channel,
            thread_ts: message.data.ts,
            blocks: blocks,
          });
        }
    }
  } catch (err) {
    return err.response;
  } */
};
